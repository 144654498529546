import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { ReplaySubject, takeUntil } from 'rxjs';
import { SignUpService } from '../auth/core/sign-up.service';

@Component({
	selector: 'app-accept-invitation',
	templateUrl: './accept-invitation.component.html',
	styleUrl: './accept-invitation.component.scss'
})
export class AcceptInvitationComponent implements OnInit, OnDestroy {
	private readonly _onDestroy$: ReplaySubject<void>;

	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private _signUpService: SignUpService
	) {
		this._onDestroy$ = new ReplaySubject<void>(1);
	}

	public ngOnInit(): void {
		this._route.paramMap.pipe(takeUntil(this._onDestroy$)).subscribe((params: ParamMap) => {
			let userType: 'us' | 'mexican' = 'us';
			if (params.get('user_type') === 'mx') {
				userType = 'mexican';
			}
			this._router.navigate(['auth', 'sign-up', userType, params.get('code')]);
			this._signUpService.invitationId = +params.get('invitation_id');
		});
	}

	public ngOnDestroy(): void {
		this._onDestroy$.next();
		this._onDestroy$.complete();
	}
}
